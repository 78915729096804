import Vue from 'vue';
import { DataOptions } from 'vuetify';
import BaseApi from '@/services/api/BaseApi';
import { Component } from 'vue-property-decorator';
import LazySearch from '@/components/common/LazySearch.vue';

@Component({
    components: {
        LazySearch
    }
})
export default class BaseList extends Vue {
    public options: DataOptions = {} as DataOptions;

    public total: number = 0;

    public page: number = 1;

    public apiClient = (new BaseApi());

    public itemsPerPage: number = 10;

    public loading: boolean = false;

    public search: string = '';

    public items: Record<string, any>[] = [];

    public sortBy: string = '';

    public sortDir: string = 'asc';

    public filters: Record<string, string | null> = {
        search: null
    };

    public footerProps: Record<string, boolean | number[]> = {
        showFirstLastPage: true,
        'items-per-page-options': [10, 15, 20, 50]
    };

    public optionChanged(): void {
        const { page, itemsPerPage, sortBy, sortDesc } = this.options;

        this.page = page;
        this.sortBy = sortBy[0];
        this.sortDir = sortDesc[0] ? 'desc' : 'asc';
        this.itemsPerPage = itemsPerPage;

        this.getItems();
    }

    public async getItems(shouldDropPage: boolean = false): Promise<void> {
        this.loading = true;
        this.items = [];

        if (shouldDropPage) {
            this.page = 1;
        }

        try {
            const response = await this.apiClient.getItems(
                this.page, this.itemsPerPage, this.filters, this.sortBy, this.sortDir
            );
            const { data: { items: { data, total } } } = response;

            this.items = data;
            this.total = total;
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    }

    public toDateString(date: string): string {
        return (new Date(date)).toDateString();
    }
}
