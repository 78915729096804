import { AxiosPromise } from 'axios';
import HttpAdapter from '@/adapters/HttpAdapter';

export default class BaseApi {
    protected uri: string = process.env.VUE_APP_BACKEND_URI;
    protected type: string = '';

    public getItems(
        page: number,
        perPage: number,
        filters: Record<string, string | null>,
        sortBy: string = '',
        sortDir: string = 'asc'
    ): AxiosPromise {
        let endpoint = `/api/admin/${this.type}?page=${page}&per_page=${perPage}`;

        Object.keys(filters).forEach(key => {
            if (filters[key]) {
                endpoint += `&${key}=${filters[key]}`;
            }
        });

        if (sortBy) {
            endpoint += `&sort=${sortBy}&dir=${sortDir}`;
        }

        return HttpAdapter.get(`${this.uri}${endpoint}`);
    }

    public getDefaultEntity(): AxiosPromise {
        return HttpAdapter.get(`${this.uri}/api/admin/${this.type}/create`);
    }

    public getEntityById(id: number): AxiosPromise {
        return HttpAdapter.get(`${this.uri}/api/admin/${this.type}/${id}/edit`);
    }

    public update(id: number, data: FormData|JSON): AxiosPromise {
        const uri = `${this.uri}/api/admin/${this.type}/${id}`;

        if (data instanceof FormData) {
            return HttpAdapter.post(uri, data);
        }

        return HttpAdapter.put(uri, data);
    }

    public destroy(id: number): AxiosPromise {
        return HttpAdapter.delete(`${this.uri}/api/admin/${this.type}/${id}`);
    }

    public create(data: FormData|JSON): AxiosPromise {
        return HttpAdapter.post(`${this.uri}/api/admin/${this.type}`, data);
    }
}
