











import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class LazySearch extends Vue {
    public search: string = '';

    public timer: number = 500;

    public timeout: number = 0;

    public doSearch(): void {
        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
            this.$emit('input', this.search);
        }, this.timer);
    }
}
